import axios from "axios";
import { ElNotification } from "element-plus";

/** Default axios instance */
const instance = axios.create({
  // baseURL: "https://projects.pacewisdom.in",
  // baseURL: "http://127.0.0.1:8000",
  baseURL: "https://projects-timesheet-dev.pacewisdom.in",
  headers: {
    "content-type": "application/json",
  },
});

/** Get the Authorization Header */
instance.interceptors.request.use((config) => {
  config.headers.Authorization = "Bearer " + localStorage.getItem("P2");
  return config;
});

instance.interceptors.request.use((config) => {
  return config;
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.data.code == "token_not_valid") {
      localStorage.clear();
      window.location.href = "/";
    }

    if (error.response.data.status != "200" && error.response.data.detail) {
      ElNotification.error({
        title: "Error",
        message: error.response.data.detail,
        position: "bottom-right",
      });
    }

    if (error.response.data.status != "200" && error.response.data.reason) {
      ElNotification.error({
        title: "Error",
        message: error.response.data.reason,
        position: "bottom-right",
      });
    }
  }
);

export default instance;
